import React from "react";
import "../assets/common.css";
import axios from "axios";
import { BaseUrl } from "../common/api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { withRouter, Redirect } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { PrimaryButton, SecondaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      collection: [],
      collect: [],
      product: [],
      loading: false,
      url: "",
      colors: [],
      color: [],
      prices: [],
      price: [],
      price_min: [],
      price_max: [],
      dates: [],
      date: [],
      deliveryDate_min: [],
      deliveryDate_max: [],
      styles: [],
      style: [],
      rooms: [],
      room: [],
      sizes: [],
      size: [],
      error: "",
      testIsChecked: false,
      show: [],
      type_data: [],

      // cart : cookies.get('cart'),
    };
    this._isMounted = false;
    // detecting the language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.rectangul = "To`rtburchak";
      this.Oval = "Oval";
      this.square = "Kvadrat";
      this.unusual = "Noodatiy";
      this.metric = "Metrli";
    } else {
      this.name = "nameRu";
      this.rectangul = "Прямоугольный";
      this.Oval = "Овал";
      this.square = "Квадратный";
      this.unusual = "Необычный";
      this.metric = "Метражный";
    }
    // holding the changes by user function binding
    this.handleChange = this.handleChange.bind(this);
    // dropping down selected filters function binding
    this.openContent = this.openContent.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.collect_data = [];
    this.color_data = [];
    this.price_data = [];
    this.date_data = [];
    this.style_data = [];
    this.room_data = [];
    this.check = false;
    this.type_data = [];
    this.sizesId = [];
    this.path = "";
    this.show = [];
    this.types = {
      1: this.rectangul,
      0: this.Oval,
      KV: this.square,
      NO: this.unusual,
      2: this.metric,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({ propers: this.props });
    // deteceting url queries for filter
    this.queryString = require("query-string");
    this.query = new URLSearchParams(this.props.location.search);
    this.collection_id = this.query.get("collection_id");
    this.category_id = this.query.get("category_id");
    this.ser = this.query.get("query");
    this.collection = this.query.get("collection");
    this.colors = this.query.get("colors");
    this.styles = this.query.get("styles");
    this.rooms = this.query.get("rooms");
    this.sizes = this.query.get("sizes");
    this.length_value = this.query.get("length");
    this.width_value = this.query.get("width");
    this.price_min = this.query.get("price_min");
    this.price_max = this.query.get("price_max");
    this.deliveryDate_min = this.query.get("deliveryDate_min");
    this.deliveryDate_max = this.query.get("deliveryDate_max");
    this.shapes = this.query.get("shapes");
    this.prices = this.query.get("prices");
    this.dates = this.query.get("dates");
    this.nameUz = this.query.get("nameUz");
    this.nameRu = this.query.get("nameRu");
    // adding to list matching queries
    if (this.collection) {
      this.collection
        .split(",")
        .map((item) => this.collect_data.push(parseInt(item)));
      this.show.push(1);
    }
    if (this.colors) {
      this.colors
        .split(",")
        .map((item) => this.color_data.push(parseInt(item)));
      this.show.push(5);
    }
    if (this.styles) {
      this.styles
        .split(",")
        .map((item) => this.style_data.push(parseInt(item)));
      this.show.push(2);
    }
    if (this.rooms) {
      this.rooms.split(",").map((item) => this.room_data.push(parseInt(item)));
      this.show.push(3);
    }
    if (this.sizes) {
      this.sizes.split(",").map((item) => this.sizesId.push(parseInt(item)));
    }
    if (this.width_value && this.length_value) {
      this.length = this.length_value;
      this.width = this.width_value;
      this.show.push(4);
    }
    if (this.shapes) {
      this.type_data = [...this.state.type_data];
      this.shapes.split(",").map((item) => this.type_data.push(item));
      this.show.push(6);
      this._isMounted &&
        this.setState({
          type_data: this.type_data,
        });
    }
    if (this.prices) {
      this.prices
        .split(",")
        .map((item) => this.price_data.push(parseInt(item)));
      this.show.push(7);
    }
    if (this.dates) {
      this.dates.split(",").map((item) => this.date_data.push(parseInt(item)));
      this.show.push(8);
    }
    // sidebar sends two type of filters by category and all
    if (this.props.location.pathname === "/searcht") {
      this.search_url = BaseUrl + `colfilters`;
    } else {
      this.search_url = BaseUrl + `colfilters/${this.category_id}`;
    }
    await axios
      .get(this.search_url)
      .then((res) => {
        const collection = res.data;
        this._isMounted && this.setState({ collection, show: this.show });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        console.log(err.response);
      });
    // fetching all data related to sidebar
    await axios.get(BaseUrl + "sidebar"+ `?category_id=${this.category_id}`).then((res) => {
      const data = res.data;
      const colors = data["colors"];
      const prices = data["prices"];
      const styles = data["styles"];
      const rooms = data["rooms"];
      const dates = data["dates"];
      const sizes = data["sizes"];
      this._isMounted &&
        this.setState({
          colors,
          prices,
          styles,
          rooms,
          dates,
          sizes,
        });
    });
  }
  async componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.parse = this.queryString.parse(this.props.location.search);
      this.category_id = this.parse.category_id;
      await axios.get(BaseUrl + "sidebar"+ `?category_id=${this.category_id}`).then((res) => {
        const data = res.data;
        console.log(res.data);
        const colors = data["colors"];
        const prices = data["prices"];
        const styles = data["styles"];
        const rooms = data["rooms"];
        const dates = data["dates"];
        const sizes = data["sizes"];
        this._isMounted &&
          this.setState({
            colors,
            prices,
            styles,
            rooms,
            dates,
            sizes,
          });
      });
  }
}
  // holds user's entering width and length for filter
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "width") {
      this.width = value;
    }
    if (name === "length") {
      this.length = value;
    }
  };
  // get user inpputs as a filters
  getData = (row, e, data) => {
    if (e.target.checked) {
      data.push(row);
    } else {
      data.splice(data.indexOf(row), 1);
    }
    if (data === this.type_data) {
      this._isMounted &&
        this.setState({
          type_data: this.type_data,
        });
    }
  };
  // collecting selected filters and sends the request to api
  handleSubmit = (e) => { 
    e.preventDefault();
    const array = [];
    const price_min_array = [];
    const price_max_array = [];
    const array_date = [];
    const date_min_array = [];
    const date_max_array = [];

    for (let i = 0; i < this.date_data.length; i++) {
      array_date[i] = this.state.dates[this.date_data[i] - 1];
      date_min_array[i] = array_date[i].date_min;
      date_max_array[i] = array_date[i].date_max;
    }
    for (let i = 0; i < this.price_data.length; i++) {
      array[i] = this.state.prices[this.price_data[i] - 1];
      price_min_array[i] = array[i].price_min;
      price_max_array[i] = array[i].price_max;
    }

    const arr3 = [...price_min_array, ...price_max_array];
    const arr4 = [...date_min_array, ...date_max_array];

    this.new_pathname = this.queryString.stringify(
      {
        collection: this.collect_data,
        colors: this.color_data,
        styles: this.style_data,
        rooms: this.room_data,
        price_min: arr3.sort()[0],
        price_max: arr3.sort()[arr3.length - 1],
        deliveryDate_min: arr4.sort()[0],
        deliveryDate_max: arr4.sort()[arr4.length - 1],
        length: this.length,
        width: this.width,
        shapes: this.type_data,
        prices: this.price_data,
        dates: this.date_data,
        nameUz: this.nameUz,
        nameRu: this.nameRu,
        category_id: this.category_id,
        collection_id: this.collection_id,
        offset: 0,
        region: cookies.get("Region"),
      },
      {
        skipNull: true,
        arrayFormat: "comma",
      }
    );

    this.pathname = this.props.location.pathname;
    this._isMounted &&
      this.setState({
        loading: true,
      });
    // posting matching query for collecting filters as a statistics
    axios
      .post(BaseUrl + "filters/", { filters: this.new_pathname })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        console.log(error.response);
      });
  };
  // clear all filter by refreshing the page

  clearFilter = (e) => {
    e.preventDefault();
    this.queryString = require("query-string");
    const parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    if (parsed.offset) {
      this.offset = parsed.offset;
    } else {
      this.offset = 0;
    }
    if (parsed.collection) {
      let collection = parsed.collection;
      if (typeof collection === "string") {
        this.co_id = collection;
      } else {
        this.co_id = collection[0];
      }
    }

    if (!this.collection) {
      this.refPath =
        this.props.location.pathname +
        `?category_id=${this.category_id}&offset=${this.offset}`;
    } else {
      this.refPath =
        this.props.location.pathname +
        `?category_id=${this.category_id}&collection=${this.co_id}&offset=${this.offset}`;
    }
    this.props.history.push(this.refPath);
    window.location.reload(false);
  };
  // dropping down selected filters
  openContent = (id) => {
    this.show = this.state.show;
    if (this.show.includes(id)) {
      this.show.splice(this.show.indexOf(id), 1);
    } else {
      this.show.push(id);
    }
    this._isMounted &&
      this.setState({
        show: this.show,
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className={` ${
          this.props.filterShow
            ? "filter-close"
            : "filters-container filters-section"
        }`}
      >
        <div className="filters-box">
          <div className={` mr-2 pt-2 `}>
            <div className="scrol-filters">
              {/* in the category.js filter doesn't show filters by collections */}
              {(this.props.location.pathname === "/collection" ||
                this.props.location.pathname === "/searcht") && (
                <div
                  className={this.state.show.includes(1) ? "show-content" : "drop-show"}
                >
                  <div
                    className="filter-title pt-2"
                    onClick={() => this.openContent(1)}
                  >
                    <span className="text medium">{t("Collections.text")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                  </div>
                  <div className="content">
                    <div className="filter-fields">
                      {this.state.collection.map((col) => (
                        <label
                          key={col.id}
                          htmlFor={col.id + col[this.name]}
                          className="checkbox-flex checkbox-container"
                        >
                          <input
                            type="checkbox"
                            id={col.id + col[this.name]}
                            name={col.id}
                            value="collection"
                            defaultChecked={
                              this.collect_data.includes(col.id)
                                ? true
                                : this.check
                            }
                            onChange={(e) =>
                              this.getData(col.id, e, this.collect_data)
                            }
                          />
                          <span className="checkmark"></span>
                          <span className="checktitle text-sm">
                            {col.nameUz}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                

                </div>
                
              )}

              {this.state.styles.length > 0 &&(<div
                className={this.state.show.includes(2) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(2)}
                >
                  <span className="text medium">{t("Styles.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.styles.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.id + col[this.name]}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.id + col[this.name]}
                          name={col.id}
                          value="styles"
                          defaultChecked={
                            this.style_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.style_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {col[this.name]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
 
              </div>)}

              <div
                className={this.state.show.includes(3) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(3)}
                >
                  <span className="text medium">{t("Rooms.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.rooms.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.id + col[this.name]}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.id + col[this.name]}
                          name={col.id}
                          value="rooms"
                          defaultChecked={
                            this.room_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.room_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {col[this.name]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>


              </div>
              <div
                className={this.state.show.includes(4) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(4)}
                >
                  <span className="text medium">{t("Sizes.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    <div style={{ position: "relative" }}>
                      <label>{t("Width.text")}:</label>
                      <br />
                      <input
                        type="text"
                        defaultValue={this.width_value}
                        name="width"
                        onChange={this.handleChange}
                        className="input-container"
                      />
                      <span className="unit">{t("cm.text")}</span>
                    </div>
                    <div style={{ position: "relative" }}>
                      <label>{t("length.text")}:</label>
                      <br />
                      <input
                        type="text"
                        defaultValue={this.length_value}
                        name="length"
                        onChange={this.handleChange}
                        className="input-container"
                      />
                      <span className="unit">{t("cm.text")}</span>
                    </div>
                  </div>
                </div>

              </div>

              <div
                className={this.state.show.includes(5) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(5)}
                >
                  <span className="text medium">{t("Color.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.colors.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.id + col[this.name]}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.id + col[this.name]}
                          name={col.id}
                          value="colors"
                          defaultChecked={
                            this.color_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.color_data)
                          }
                        />
                        <span className="checkmark" style={{ background: col.color_code }}></span>
                        <span className="checktitle text-sm">
                          {col[this.name]}
                        </span>
                        {/* <div className="colors">
                          <div
                            className="single-color"
                            style={{ background: col.color_code }}
                          ></div>
                          <div
                            className="single-color"
                            style={{ background: col.extra_color }}
                          ></div>
                        </div> */}
                      </label>
                    ))}
                  </div>
                </div>

              </div>

              <div
                className={this.state.show.includes(6) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(6)}
                >
                  <span className="text medium">{t("Shape.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {Object.keys(this.types).map((key, index) => (
                      <label
                        key={index}
                        htmlFor={index + key}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={index + key}
                          name={key}
                          value="shapes"
                          checked={
                            this.state.type_data.includes(key)
                              ? true
                              : this.check
                          }
                          onChange={(e) => this.getData(key, e, this.type_data)}
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {this.types[key]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

              </div>

              <div
                className={this.state.show.includes(7) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(7)}
                >
                  <span className="text medium">{t("Price.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.prices.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.price_max}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.price_max}
                          defaultValue={col.id}
                          name="prices"
                          defaultChecked={
                            this.price_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.price_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          <span>
                            <CurrencyFormat
                              value={col.price_min}
                              displayType={"text"}
                              thousandSeparator=" "
                            />
                          </span>

                          <span> - </span>
                          <span>
                            <CurrencyFormat
                              value={col.price_max}
                              displayType={"text"}
                              thousandSeparator=" "
                            />
                          </span>
                          <span> {t("suffix.text")}</span>
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
           

              </div>

              <div 
              style={{"border": "none"}}
                className={this.state.show.includes(8) ? "show-content" : "drop-show"}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(8)}
                >
                  <span className="text medium">{t("DeliveryDate.text")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M15 1L8 7L1 1" stroke="#01091C"/>
                  </svg>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.dates.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.date_max + "date"}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.date_max + "date"}
                          name="dates"
                          defaultValue={col.id}
                          defaultChecked={
                            this.date_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.date_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          <span>{col.date_min}</span>

                          <span> - </span>
                          <span>{col.date_max}</span>
                          <span> {t("day.text")}</span>
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
      

              </div>
            </div>

            <PrimaryButton
              onClick={(e) => {
                this.handleSubmit(e);
                // this.props.filterToggle();
              }}
              style={{background: "#C89B71", width: '100%'}}
              value={t("UseFilters.text")}
            />
            <SecondaryButton
              onClick={(e) => {
                this.clearFilter(e);
                // this.props.filterToggle();
              }}
              style={{width: '100%'}}
              value={t("ClearFilters.text")}
            />
            {this.state.loading && (
              <Redirect
                to={{
                  pathname: this.pathname,
                  search: `?${this.new_pathname}`,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(SideBar));
